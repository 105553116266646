$(document).ready(function(){
	 $('.kit__fit').slick({
	 	dots: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		fade: true,
		asNavFor: '.kit__nav'
	});
	$('.kit__nav').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: '.kit__fit',
		arrows: false,
		centerMode: true,

		focusOnSelect: true,
		responsive: [
		{
			breakpoint: 576,
			settings: {
			slidesToShow: 1,
			fade: true
			}
		}
		]

	});
	setTimeout(function () {
		$('#ModalCenter').modal()
	},10000);
});